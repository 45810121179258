<template>
  <div class="search">
    <div class="pc" v-if="equipment == 'pc'">
       <navig :w="w" :only="only" :mask="mask" />
       <header>
        <div class="search_box">
          <input type="text" @keyup.13='enter' v-model="value">
          <button @click="search">搜索</button>
        </div>
        <p>本次检索为您找到 <span style="color:#FF0000;">{{total}}</span> 篇有关 <span style="color:#00B3D6;">{{searchkey}}</span> 的页面</p>
       </header>
       <main>
         <ul>
           <li v-for="item in list" @click="Jump(item.url)">
             <h2 v-html="brightenKeyword(item.title,key)">{{item.title}}</h2>
             <h3 v-html="brightenKeyword(item.desc,key)">{{item.desc}}</h3>
             <p>来源：{{item.source}}  <span>&nbsp;&nbsp;&nbsp;&nbsp;{{item.time}}</span> </p>
           </li>
         </ul>
         <div class="fenye">
          <el-pagination
            :page-size="10"
            :pager-count="11"
            layout="prev, pager, next,jumper"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
       </main>
       <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <webNav  :mask="mask" />
      <header>
        <div class="input_box">
          <input type="text" v-model="value">
          <button @click="search">搜索</button>
        </div>
        <p>本次检索为您找到 <span style="color:#FF0000;">{{total}}</span> 篇有关 <span style="color:#00B3D6;">{{searchkey}}</span> 的页面</p>
      </header>
      <main>
        <ul>
          <li v-for="item in list" @click="Jump(item.url)">
            <h2 v-html="brightenKeyword(item.title,key)">{{item.title}}</h2>
            <h3 v-html="brightenKeyword(item.desc,key)">{{item.desc}}</h3>
            <p>来源：{{item.source}}  <span>&nbsp;&nbsp;&nbsp;&nbsp;{{item.time}}</span> </p>
          </li>
        </ul>
        <div class="web_fenye" @click="webSearch">点击加载</div>
      </main>
      <up />
    </div>
  </div>
</template>


<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";

export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up
  },
  data() {
    return {
      w: undefined,
      equipment: "",
      only: true,
      mask: "NO",
      showFooter: true,
      wd: "",
      pageNum: 1,
      pageSize: 10,
      list: [],
      total: 0,
      searchkey: "",
      value: "", // 搜索内容
      webValue: ""
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    this.wd = this.getQueryValue("wd");
    // console.log(decodeURIComponent(this.wd));
    this.value = decodeURIComponent(this.wd);
    console.log(this.value);
    this.getSearch();
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  watch: {
    $route(to, from) {
      this.pageNum = 1;
      this.wd = this.getQueryValue("wd");
      this.value = decodeURIComponent(this.wd);
      this.list = [];
      this.getSearch();
    }
  },
  methods: {
    Jump(url) {
      location.href = url;
      // console.log(url);
      // if (target.indexOf("http") != -1) {
      //   window.open(target, "_blank");
      // } else if (target && target !== "#") {
      //   this.$router.push({ path: target + "?dataId=" + item.dataId });
      //   this.drawer = false;
      // }
    },
    webSearch() {
      this.pageNum++;
      this.getSearch();
    },
    search() {
      if (this.value) {
        this.$router.push({ path: `/search?wd=${this.value}` });
      }
    },
    enter() {
      if (this.value) {
        this.$router.push({ path: `/search?wd=${this.value}` });
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getSearch();
    },
    async getSearch() {
      const res = await this.axios.get(`/v1/api/search`, {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          wd: this.wd
        }
      });
      if (res.code == "00") {
        if (this.equipment == "pc") {
          this.list = res.data.rows;
        } else {
          if (res.data.rows.length > 0) {
            res.data.rows.forEach(item => {
              this.list.push(item);
            });
          } else {
            this.$toast("暂无数据");
          }
        }
        this.key = res.words;
        this.searchkey = res.words.toString();
        this.total = res.data.total;
      }
    },
    brightenKeyword(str, keyword) {
      if (keyword.length == 0) return str;
      keyword = keyword.toString();
      let keywordArr = keyword.split(",");
      str = str + "";
      keywordArr.forEach(item => {
        var heights = str => `<span style="color:#01b2db;">${str}</span>`;
        var a = new RegExp(item, "gi");
        str = str.replace(a, function(num) {
          return heights(num);
        });
      });
      return str;
    }
  }
};
</script>


<style lang="less" scoped>
.search {
  .pc {
    header {
      height: 409px /* 409/100 */;
      padding-top: 1.59rem /* 159/100 */;
      background: #f9f9f9;
      .search_box {
        margin: auto;
        margin-top: 81px /* 81/100 */;
        width: 9.01rem /* 901/100 */;
        height: 0.73rem /* 73/100 */;
        position: relative;
        input {
          width: 9.01rem /* 901/100 */;
          height: 0.73rem /* 73/100 */;
          background: #fff;
          border-radius: 0.1rem /* 10/100 */;
          padding-left: 0.2rem /* 20/100 */;
          font-size: 0.19rem /* 19/100 */;
        }
        button {
          width: 1.81rem /* 181/100 */;
          height: 0.73rem /* 73/100 */;
          border-radius: 0.1rem /* 10/100 */;
          background: #01b2db;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0.19rem /* 19/100 */;
          color: #fff;
          cursor: pointer;
        }
      }
      p {
        text-align: center;
        margin-top: 0.36rem /* 36/100 */;
      }
    }
    main {
      padding-left: 1.93rem /* 193/100 */;
      padding-right: 1.9rem /* 190/100 */;
      ul {
        li {
          padding-top: 0.48rem /* 48/100 */;
          border-bottom: 1px solid #01b2db;
          cursor: pointer;
          h2 {
            font-size: 0.24rem /* 24/100 */;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.24rem /* 24/100 */;
            margin-bottom: 0.32rem /* 32/100 */;
          }
          h3 {
            font-size: 19px;
            font-family: Alibaba PuHuiTi;
            font-weight: 300;
            color: #000000;
            line-height: 36px;
            margin-bottom: 0.82rem /* 82/100 */;
          }
          p {
            font-size: 16px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #b9b9b9;
            margin-bottom: 0.32rem /* 32/100 */;
          }
        }
      }
      .fenye {
        position: relative;
        height: 160px;
        margin-top: 0.76rem /* 76/100 */;
        .el-pagination {
          position: absolute;
          right: 0;
          /deep/.el-pagination__editor {
            padding: 0 5px;
          }
          /deep/.btn-next {
            i {
              font-size: 16px;
            }
          }
          /deep/.btn-prev {
            i {
              font-size: 16px;
            }
          }
          /deep/.el-pager {
            li {
              background: #fff;
              min-width: 20px;
              // line-height: 20px;
              margin-right: 0.15rem /* 20/100 */;
            }
            li.active {
              color: #fff;
              background: #01b2db;
            }
          }
        }
      }
    }
  }
  .web {
    header {
      height: 200px;
      background: #f9f9f9;
      padding-top: 80px;
      .input_box {
        height: 37px;
        position: relative;
        width: 85%;
        margin: auto;
        border-radius: 5px;
        margin-top: 20px;
        input {
          width: 85%;
          height: 37px;
          // margin: 0 auto;
          // position: absolute;
          // left: 50%;
          // transform: translate(-50%);
          border-radius: 5px;
          padding-left: 11px;
        }
        button {
          width: 80px;
          height: 37px;
          position: absolute;
          right: 0;
          border-radius: 5px;
          background: #01b2db;
          color: #fff;
        }
      }
      p {
        font-size: 12px;
        color: #939393;
        text-align: center;
        margin-top: 20px;
      }
    }
    main {
      ul {
        padding-top: 25px;
        padding-left: 24px;
        padding-right: 25px;
        li {
          margin-bottom: 24px;
          border-bottom: 1px solid #01b2db;
          h2 {
            font-size: 14px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            line-height: 13px;
            margin-bottom: 16px;
            line-height: 20px;
          }
          h3 {
            font-size: 11px;
            font-family: Alibaba PuHuiTi;
            font-weight: 300;
            line-height: 18px;
            margin-bottom: 24px;
          }
          p {
            font-size: 9px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #b9b9b9;
            line-height: 18px;
            margin-bottom: 15px;
          }
        }
      }
    }
    .web_fenye {
      height: 60px;
      font-size: 11px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #9d9d9d;
      line-height: 60px;
      text-align: center;
    }
  }
}
</style>

