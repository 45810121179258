<template>
  <div class="chairman" v-if="showpage">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <img src="~img/chairman/01.png" class="pc_bg">
      <main>
        <img src="~img/chairman/04.png" class="pc_logo">
        <div class="content">
          <div class="left">
            <img src="~img/chairman/02.png" class="man_img">
            <!-- <img src="~img/chairman/03.png" class="name_img"> -->
          </div>
          <div class="right">
            <img src="~img/chairman/03.png" class="name_img">
            <h4>安谋科技董事长兼总经理</h4>
            <div class="fenge"></div>
            <p>吴雄昂先生是安谋科技（中国）有限公司（“安谋科技”）董事长兼总经理，全面领导公司的战略、业务、经营、投资和生态发展等。</p>
            <p>
              吴雄昂先生于2004年加入Arm公司，2007年出任中国区销售副总裁，2009年被擢升为中国区总经理兼销售副总裁。2011年出任中国区总裁；2013年1月升任为大中华区总裁。2014年1月加入Arm公司全球执行委员会，任全球执行副总裁。
            </p>
            <p>2018年4月，Arm公司中国业务完成分拆，成立了中资控股、独立运营的合资公司安谋科技（中国）有限公司，吴雄昂先生担任董事长兼总经理至今。</p>
            <p>吴雄昂先生获得美国密歇根大学电气工程学士、硕士学位，加州大学伯克利分校MBA学位，以及斯坦福大学领袖项目证书。</p>
            <p> 2017年，吴雄昂先生荣获上海市白玉兰纪念奖。</p>
          </div>
        </div>
      </main>
      <pc-footer :showFooter="showFooter" />

    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <img src="~img/chairman/06.png" class="web_bg">
      <main>
        <img src="~img/chairman/07.png" class="web_logo">
        <img src="~img/chairman/08.png" class="web_man">
        <img src="~img/chairman/03.png" class="web_name">
        <div class="content_web">
          <h4>安谋科技董事长兼总经理</h4>
          <div class="fenge"></div>
          <p>吴雄昂先生是安谋科技（中国）有限公司（“安谋科技”）董事长兼总经理，全面领导公司的战略、业务、经营、投资和生态发展等。</p>
          <p>
            吴雄昂先生于2004年加入Arm公司，2007年出任中国区销售副总裁，2009年被擢升为中国区总经理兼销售副总裁。2011年出任中国区总裁；2013年1月升任为大中华区总裁。2014年1月加入Arm公司全球执行委员会，任全球执行副总裁。
          </p>
          <p>2018年4月，Arm公司中国业务完成分拆，成立了中资控股、独立运营的合资公司安谋科技（中国）有限公司，吴雄昂先生担任董事长兼总经理至今。</p>
          <p>吴雄昂先生获得美国密歇根大学电气工程学士、硕士学位，加州大学伯克利分校MBA学位，以及斯坦福大学领袖项目证书。</p>
          <p> 2017年，吴雄昂先生荣获上海市白玉兰纪念奖。</p>
        </div>

      </main>
      <div class="foot">
        <web-footer />
      </div>
    </div>

  </div>
</template>
<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";

export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: "",
      equipment: "",
      only: true,
      showFooter: true,
      list: [],
      title: "",
      showpage: false,
    };
  },
  created() {
    this.title = this.getQueryValue("title");
    console.log(this.title);
    if (this.title === "chairman") {
      console.log("显示");
      this.showpage = true;
    } else {
      console.log("隐藏");
      this.showpage = false;
    }
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    // console.log(this.equipment);
  },
  mounted() {
    document.title = "董事长兼总经理简介 - 安谋科技";
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.chairman {
  .pc {
    background: #000;
    position: relative;
    .pc_bg {
      position: absolute;
      top: 0;
    }
    main {
      background: rgba(0, 0, 0, 0.85);
      position: relative;
      padding-top: 2.89rem;
      .pc_logo {
        width: 0.39rem;
        position: absolute;
        top: 3.39rem;
        right: 0.74rem;
      }
      .content {
        // width: 100%;
        margin: auto;
        margin-left: 3.07rem;
        margin-right: 2.9rem;
        display: flex;
        margin-bottom: 1.33rem;
        .left {
          width: 5.5rem;
          position: relative;
          .man_img {
            width: 5.5rem;
          }
          .name_img {
            width: 2.7rem;
            position: absolute;
            top: 0.89rem;
            right: -1.92rem;
          }
        }
        .right {
          width: 7.73rem;
          padding-top: 0.54rem;
          padding-left: 0.58rem;
          .name_img {
            width: 2.7rem;
          }
          h4 {
            font-size: 0.32rem;
            color: #fff;
            line-height: 0.32rem;
            margin-top: 0.66rem;
          }
          .fenge {
            width: 1.21rem;
            height: 0.03rem;
            background: #fff;
            margin-top: 0.17rem;
            margin-bottom: 0.54rem;
          }
          p {
            font-size: 0.18rem;
            color: #fff;
            line-height: 0.31rem;
            margin-bottom: 0.29rem;
          }
        }
      }
    }
  }
  .web {
    background: #000;
    .web_bg {
      position: absolute;
    }
    main {
      background: rgba(0, 0, 0, 0.8);
      position: relative;
      padding-top: 100px;
      .web_logo {
        width: 191px;
        float: right;
        margin-right: 25px;
      }
      .web_man {
        width: 218px;
        margin: auto;
        margin-top: 62px;
      }
      .web_name {
        width: 130px;
        margin: auto;
        margin-top: 27px;
      }
      .content_web {
        padding-left: 28px;
        padding-right: 24px;
        h4 {
          font-size: 16px;
          color: #d2d2d2;
          margin-top: 19px;
          text-align: center;
        }
        .fenge {
          width: 61px;
          height: 2px;
          background: #fff;
          margin: auto;
          margin-top: 9px;
          margin-bottom: 21px;
        }
        p {
          font-size: 12px;
          color: #d2d2d2;
          margin-bottom: 20px;
        }
      }
    }
    .foot {
      position: relative;
      padding-bottom: 10px;
      background-color: #000;
    }
  }
}
</style>