<template>
  <div class="video">
    <video controls="false" class="xuan_video" :poster="videoImg" ontrols="controls" :src="videoUrl"
      v-if="equipment == 'pc'" ref="xc_video" controlsList="nodownload noremoteplayback">
      您的浏览器不支持Video标签。
    </video>
    <video controls="false" class="web_video" :poster="videoImg" ontrols="controls" :src="videoUrl"
      v-if="equipment == 'web'" controlsList="nodownload noremoteplayback">
      您的浏览器不支持Video标签。
    </video>
  </div>
</template>


<script>
export default {
  data() {
    return {
      video: {},
      infoId: "",
      text: "",
      videoImg: "",
      videoUrl: "",
      w: "",
      equipment: "",
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    this.infoId = this.getQueryValue("infoId");
    this.text = this.getQueryValue("text");
    this.getDetail();
  },
  mounted() {
    this.video = this.$refs.xc_video;
    this.video["disablePictureInPicture"] = true;
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        this.h = document.documentElement.clientHeight; // 视口的高度
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  methods: {
    async getDetail() {
      const res = await this.axios(
        `/v1/api/corepower_detail?infoId=${this.infoId}`
      );
      if (res.code == "00") {
        res.detailList.forEach((item) => {
          if (item.style == 5) {
            console.log(item);
            let video = item.content.videos.filter(
              (val) => val.text == this.text
            );
            this.videoImg = video[0].img;
            this.videoUrl = video[0].video;
            console.log(this.videoImg);
          }
        });
      } else {
        this.$message.error("数据请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.video {
  height: 100vh;
  max-height: 100vh;
  background-color: #000;
  position: relative;
  .xuan_video {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .web_video {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
</style>


